import axios from 'axios'

export const client = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
});

client.interceptors.response.use(
	( response ) => {
		return response;
	},
	function ( error ) {
		var response = ( error.response && error.response.data ? error.response.data : error );
		response.status = ( error.response && error.response.status ? error.response.status : 500 )
		return Promise.reject( response );
	}
)

export default {

	async auth( payload ) {

		return client.post( '/api/auth', payload )

	},

	async login( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/login', payload )

	},

	async login_with_code( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/login-with-code', payload )

	},

	async register( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/register', payload )

	},

	async check_email( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/check-email', payload )

	},

	async check_password( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/check-password', payload )

	},

	async verify( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/verify', payload )

	},

	async resend_verify( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/resend-verify', payload )

	},

	async send_password_reset( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/send-password-reset', payload )

	},

	async reset_password( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/reset-password', payload )

	},

	async logout( payload ) {

		await client.get( '/sanctum/csrf-cookie' )

		return client.post( '/api/logout', payload )

	},

	async index( payload ) {

		return client.get( '/api/user', payload )

	},

	async update( user_id, payload ) {

		return client.put( '/api/user/' + user_id, payload )

	},

	async invite( payload ) {

		return client.post( '/api/user/invite', payload )

	}

}