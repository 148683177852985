import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index';
import user_service from '@/services/user_service'

const routes = [
	{
		path: '/',
		component: () => import('../pages/register_wizard.vue' /* webpackChunkName: "resource/js/pages/register_wizard" */),
		name: 'login',
		meta: { label: 'Login' },
	},
	{
		path: '/logout',
		component: () => import('../pages/logout.vue' /* webpackChunkName: "resource/js/pages/logout" */),
		name: 'logout',
		meta: { label: 'Logout' },
	},
	// {
	// 	path: '/register',
	// 	component: () => import('../pages/register_wizard.vue' /* webpackChunkName: "resource/js/pages/register_wizard" */),
	// 	name: 'register',
	// 	meta: { label: 'Register' },
	// },
	// {
	// 	path: '/password',
	// 	component: () => import('../pages/password.vue' /* webpackChunkName: "resource/js/pages/password" */),
	// 	name: 'password',
	// 	meta: { label: 'Reset Password' },
	// },
	// {
	// 	path: '/reset-password',
	// 	component: () => import('../pages/reset-password.vue' /* webpackChunkName: "resource/js/pages/reset-password" */),
	// 	name: 'reset-password',
	// 	meta: { label: 'Reset Password' },
	// },
	// {
	// 	path: '/verify',
	// 	component: () => import('../pages/verify.vue' /* webpackChunkName: "resource/js/pages/verify" */),
	// 	name: 'verify',
	// 	meta: { label: 'Verify' },
	// },
	{
		path: '/workspaces',
		component: () => import('../pages/workspaces.vue' /* webpackChunkName: "resource/js/pages/workspaces" */),
		name: 'workspaces',
		meta: { label: 'Workspaces', middleware: 'auth' },
	},
	{
		path: '/workspaces/add',
		component: () => import('../pages/workspaces/add.vue' /* webpackChunkName: "resource/js/pages/workspaces/add" */),
		name: 'workspaces.add',
		meta: { label: 'Add Workspace', middleware: 'auth', types: ['admin'] },
	},
	{
		path: '/workspaces/:workspace_id',
		component: () => import('../pages/workspaces/show.vue' /* webpackChunkName: "resource/js/pages/workspaces/show" */),
		name: 'workspaces.show',
		meta: { label: 'Manage Workspace', middleware: 'auth' },
	},
	{
		path: '/workspaces/:workspace_id/destroy',
		component: () => import('../pages/workspaces/destroy.vue' /* webpackChunkName: "resource/js/pages/workspaces/destroy" */),
		name: 'workspaces.destroy',
		meta: { label: 'Delete Workspace', middleware: 'auth', types: ['admin'] },
	},
	{
		path: '/workspaces/:workspace_id/site/add/:site_id?',
		component: () => import('../pages/site/add_wizard.vue' /* webpackChunkName: "resource/js/pages/site/add_wizard" */),
		name: 'site.add',
		meta: { label: 'Add Site', middleware: 'auth', types: ['admin', 'editor'] },
	},
	{
		path: '/workspaces/:workspace_id/site/:site_id/setup',
		component: () => import('../pages/site/setup/add.vue' /* webpackChunkName: "resource/js/pages/site/setup/add" */),
		name: 'site.setup.add',
		meta: { label: 'Add Site', middleware: 'auth', types: ['admin', 'editor'] },
	},
	{
		path: '/workspaces/:workspace_id/site/:site_id',
		component: () => import('../pages/site/show.vue' /* webpackChunkName: "resource/js/pages/site/show" */),
		name: 'site.show',
		meta: { label: 'Manage Site', middleware: 'auth' },
		children: [
			{
				path: 'planning',
				meta: { label: 'Plan Your Site', middleware: 'auth' },
				children: [
					{
						path: '',
						component: () => import('../pages/site/planning/index.vue' /* webpackChunkName: "resource/js/pages/site/planning/index" */),
						name: 'site.planning',
						meta: { label: 'Plan Your Site', middleware: 'auth' },
					},
					{
						path: 'sitemap',
						component: () => import('../pages/site/planning/sitemap.vue' /* webpackChunkName: "resource/js/pages/site/planning/sitemap" */),
						name: 'site.planning.sitemap',
						meta: { label: 'Manage Your Sitemap', middleware: 'auth' },
					},
					{
						path: 'content',
						component: () => import('../pages/site/planning/content.vue' /* webpackChunkName: "resource/js/pages/site/planning/content" */),
						name: 'site.planning.content',
						meta: { label: 'Manage Your Content', middleware: 'auth' },
					},
					{
						path: ':page_id',
						component: () => import('../pages/site/planning/page.vue' /* webpackChunkName: "resource/js/pages/site/planning/page" */),
						name: 'site.planning.page',
						meta: { label: 'Edit Page Content', middleware: 'auth' },
					},
					{
						path: 'board',
						component: () => import('../pages/site/planning/board.vue' /* webpackChunkName: "resource/js/pages/site/planning/board" */),
						name: 'site.planning.board',
						meta: { label: 'Planning Board', middleware: 'auth' },
					},
				]
			},
			{
				path: 'design',
				meta: { label: 'Design Your Site', middleware: 'auth' },
				children: [
					{
						path: '',
						component: () => import('../pages/site/design/design_wizard.vue' /* webpackChunkName: "resource/js/pages/site/design/design_wizard" */),
						name: 'site.design',
						meta: { label: 'Design Your Site', middleware: 'auth' },
					},
					{
						path: 'wireframes',
						component: () => import('../pages/site/design/wireframes.vue' /* webpackChunkName: "resource/js/pages/site/design/wireframes" */),
						name: 'site.design.wireframes',
						meta: { label: 'Create Your Wireframes', middleware: 'auth' },
					},
					{
						path: 'prototype',
						component: () => import('../pages/site/design/prototype.vue' /* webpackChunkName: "resource/js/prototypes/site/design/prototype" */),
						name: 'site.design.prototype',
						meta: { label: 'Preview Your Prototype', middleware: 'auth' },
					},
					{
						path: 'menus',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.design.content.menus',
						meta: { label: 'Manage Your Menus', middleware: 'auth' },
					},
					{
						path: 'header',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.design.content.header',
						meta: { label: 'Manage Your Header', middleware: 'auth' },
					},
					{
						path: 'footer',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.design.content.footer',
						meta: { label: 'Manage Your Footer', middleware: 'auth' },
					},
					{
						path: 'post-template',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.design.content.post-template',
						meta: { label: 'Manage Your Post Template', middleware: 'auth' },
					},
					{
						path: 'search-template',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.design.content.search-template',
						meta: { label: 'Manage Your Search Results Template', middleware: 'auth' },
					},
					{
						path: 'archive-template',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.design.content.archive-template',
						meta: { label: 'Manage Your Archive Template', middleware: 'auth' },
					},
					{
						path: 'notfound-template',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.design.content.notfound-template',
						meta: { label: 'Manage Your 404 Template', middleware: 'auth' },
					},
					{
						path: ':page_id',
						component: () => import('../pages/site/design/page.vue' /* webpackChunkName: "resource/js/pages/site/design/page" */),
						name: 'site.design.page',
						meta: { label: 'Design Your Wireframe', middleware: 'auth' },
					},
				]
			},
			{
				path: 'build',
				meta: { label: 'Build Your Site', middleware: 'auth' },
				children:[
					{
						path: '',
						component: () => import('../pages/site/build/index.vue' /* webpackChunkName: "resource/js/pages/site/build/index" */),
						name: 'site.build',
						meta: { label: 'Build Your Site', middleware: 'auth' },
					},
					{
						path: 'pages',
						component: () => import('../pages/site/build/pages.vue' /* webpackChunkName: "resource/js/pages/site/build/pages" */),
						name: 'site.build.content.pages',
						meta: { label: 'Manage Your Pages', middleware: 'auth' },
					},
					{
						path: 'pages/:page_id',
						component: () => import('../pages/site/build/page.vue' /* webpackChunkName: "resource/js/pages/site/build/page" */),
						name: 'site.build.content.page',
						meta: { label: 'Manage Your Page', middleware: 'auth' },
					},
					{
						path: 'menus',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.content.menus',
						meta: { label: 'Manage Your Menus', middleware: 'auth' },
					},
					{
						path: 'header',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.content.header',
						meta: { label: 'Manage Your Header', middleware: 'auth' },
					},
					{
						path: 'footer',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.content.footer',
						meta: { label: 'Manage Your Footer', middleware: 'auth' },
					},
					{
						path: 'post-template',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.content.post-template',
						meta: { label: 'Manage Your Post Template', middleware: 'auth' },
					},
					{
						path: 'search-template',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.content.search-template',
						meta: { label: 'Manage Your Search Results Template', middleware: 'auth' },
					},
					{
						path: 'archive-template',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.content.archive-template',
						meta: { label: 'Manage Your Archive Template', middleware: 'auth' },
					},
					{
						path: 'notfound-template',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.content.notfound-template',
						meta: { label: 'Manage Your 404 Template', middleware: 'auth' },
					},
					{
						path: 'theme',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.extend.theme',
						meta: { label: 'Manage Your Theme', middleware: 'auth' },
					},
					{
						path: 'plugins',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.extend.plugins',
						meta: { label: 'Manage Your Plugins', middleware: 'auth' },
					},
					{
						path: 'install-plugins',
						component: () => import('../pages/site/wordpress.vue' /* webpackChunkName: "resource/js/pages/site/wordpress" */),
						name: 'site.build.extend.install-plugins',
						meta: { label: 'Install Plugins', middleware: 'auth' },
					},
					{
						path: 'version-control',
						component: () => import('../pages/site/build/version-control.vue' /* webpackChunkName: "resource/js/pages/site/build/version-control" */),
						name: 'site.build.extend.version-control',
						meta: { label: 'Manage Version Control', middleware: 'auth' },
					},
				]
			},
			{
				path: 'launch',
				meta: { label: 'Launch Your Site', middleware: 'auth' },
				children:[
					{
						path: '',
						component: () => import('../pages/site/launch/seo.vue' /* webpackChunkName: "resource/js/pages/site/launch/seo" */),
						name: 'site.launch',
						meta: { label: 'Manage Your SEO', middleware: 'auth' },
					},
					{
						path: 'analytics',
						component: () => import('../pages/site/launch/analytics.vue' /* webpackChunkName: "resource/js/pages/site/launch/analytics" */),
						name: 'site.launch.analytics',
						meta: { label: 'Manage Your Tracking and Analytics', middleware: 'auth' },
					},
					{
						path: 'gdpr',
						component: () => import('../pages/site/launch/gdpr.vue' /* webpackChunkName: "resource/js/pages/site/launch/gdpr" */),
						name: 'site.launch.gdpr',
						meta: { label: 'Manage Your GDPR', middleware: 'auth' },
					},
					{
						path: 'redirects',
						component: () => import('../pages/site/launch/redirects.vue' /* webpackChunkName: "resource/js/pages/site/launch/redirects" */),
						name: 'site.launch.redirects',
						meta: { label: 'Manage Your Redirects', middleware: 'auth' },
					},
					{
						path: 'performance',
						component: () => import('../pages/site/launch/performance.vue' /* webpackChunkName: "resource/js/pages/site/launch/performance" */),
						name: 'site.launch.performance',
						meta: { label: 'Manage Your Performance', middleware: 'auth' },
					},
					{
						path: 'download',
						component: () => import('../pages/site/launch/download.vue' /* webpackChunkName: "resource/js/pages/site/launch/download" */),
						name: 'site.launch.download',
						meta: { label: 'Download Your Site', middleware: 'auth' },
					},
				]
			},
		]
	},
	{
		path: '/prototype/:prototype_url',
		component: () => import('../pages/site/prototype-preview.vue' /* webpackChunkName: "resource/js/pages/site/prototype-preview" */),
		name: 'site.prototype-preview',
		meta: { label: 'Preview Prototype' },
	},
	{
		path: '/workspaces/:workspace_id/site/:site_id/destroy',
		component: () => import('../pages/site/destroy.vue' /* webpackChunkName: "resource/js/pages/site/destroy" */),
		name: 'site.destroy',
		meta: { label: 'Delete Site', middleware: 'auth', types: ['admin', 'editor'] },
	},
	{
		path: '/profile',
		component: () => import('../pages/profile.vue' /* webpackChunkName: "resource/js/pages/profile" */),
		name: 'profile',
		meta: { label: 'Profile', middleware: 'auth' },
	},
	{
		path: '/api-keys',
		component: () => import('../pages/api-keys.vue' /* webpackChunkName: "resource/js/pages/api-keys" */),
		name: 'api-keys',
		meta: { label: 'API Keys', middleware: 'auth' },
	},
	{
		path: '/team',
		component: () => import('../pages/team.vue' /* webpackChunkName: "resource/js/pages/team" */),
		name: 'team',
		meta: { label: 'Team', middleware: 'auth', types: ['admin', 'editor'] },
	},
	{
		path: '/team/brand',
		component: () => import('../pages/team/brand.vue' /* webpackChunkName: "resource/js/pages/team/brand" */),
		name: 'team.brand',
		meta: { label: 'Branding', middleware: 'auth', types: ['admin', 'editor'] },
	},
	{
		path: '/team/members',
		component: () => import('../pages/team/members.vue' /* webpackChunkName: "resource/js/pages/team/members" */),
		name: 'team.members',
		meta: { label: 'Team Members', middleware: 'auth', types: ['admin', 'editor'] },
	},
	{
		path: '/team/invite-member',
		component: () => import('../pages/team/invite-member.vue' /* webpackChunkName: "resource/js/pages/team/invite-member" */),
		name: 'team.invite-member',
		meta: { label: 'Invite Member', middleware: 'auth', types: ['admin'] },
	},
	{
		path: '/:pathMatch(.*)', 
		component: () => import('../pages/not-found.vue' /* webpackChunkName: "resource/js/pages/not-found" */),
		name: 'not-found',
		meta: { label: 'Not found' },
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach( ( to, from, next ) => 
{
	const titleEl = document.querySelector('head title');
	titleEl.textContent = 'Loading...';

	const middleware = to.meta.middleware

	const types = to.meta.types
	
	if (!middleware) {
		return next()
	}

	user_service.auth().then(( response ) => {
		
		store.commit( 'user/SET_USER', response.data )

		if ( !types || types.includes( response.data.type ) ) {
			return next()
		}

		router.push( { name: 'workspaces' } )

	}).catch( ( error ) => {
		if ( [ 401, 419 ].includes( error.status ) ) {
			router.push( { name: 'login' } )
		}

		return next()
	})
})

function waitForHSBeacon(callback, attemptsLeft = 10, interval = 500) {
	if (typeof window.Beacon !== 'undefined') {
		callback();
	} else if (attemptsLeft > 0) {
		
		setTimeout(function() {
			waitForHSBeacon(callback, attemptsLeft - 1, interval);
		}, interval);
	} else {
		console.error('Help Scout Beacon script did not load or is not defined.');
	}
}

// router.afterEach( (to, from, failure) => 
router.afterEach( (to) => 
{
	let title = to.meta.label

	const titleEl = document.querySelector('head title');
	titleEl.textContent = title + ' | AREOI';	
	
	if ( to.meta.middleware && to.meta.middleware == 'auth' ) {

		var $ = window.jQuery

		$( '[data-type="tracking"]' ).each( function() {

			var tag = document.createElement( 'script' );
			
			tag.id = $( this ).prop( 'id' );

			if ( $( this ).prop( 'src' ) ) {
				tag.src = $( this ).prop( 'src' );
			}
			if ( $( this ).html() ) {
				tag.innerText = $( this ).html().trim();
			}
			
			if ( $( this ).prop( 'type' ) == 'text/plain' ) {

				tag.setAttribute( 'async', '' );
				
				$( tag ).insertAfter( $( this ) );

				$( this ).remove();
			}
		});

		waitForHSBeacon(function() {

			var vuex = JSON.parse( localStorage.getItem('vuex') );

			var user = vuex.user.user;

			window.Beacon('identify', {
				name: user.name,
				email: user.email,
			})
		});
	}

	let url = window.location.href.replace( to.params.team_id, 'team_id' )
	let path = to.fullPath.replace( to.params.team_id, 'team_id' )

	if ( to.params.workspace_id ) {
		url = url.replace( to.params.workspace_id, 'workspace_id' )
		path = path.replace( to.params.workspace_id, 'workspace_id' )
	}

	if ( to.params.site_id ) {
		url = url.replace( to.params.site_id, 'site_id' )
		path = path.replace( to.params.site_id, 'site_id' )
	}

	let params = {
		'page_location': url,
		'page_path': path,
		'page_title': title,
	};

	window.gtag( 'page_view', params )
})

export default router