export const namespaced = true

export const getters = {
	store_industries: () => {
		return [
			{ value: undefined, name: 'Select industry' },
			{ value: 'agriculture', name: 'Agriculture' },
			{ value: 'agriculture-food', name: 'Agriculture & Food Production' },
			{ value: 'automotive', name: 'Automotive' },
			{ value: 'automotive-aerospace', name: 'Automotive & Aerospace' },
			{ value: 'chemicals-materials', name: 'Chemicals & Materials' },
			{ value: 'construction', name: 'Construction' },
			{ value: 'construction-engineering', name: 'Construction & Engineering' },
			{ value: 'education', name: 'Education' },
			{ value: 'education-training', name: 'Education & Training' },
			{ value: 'energy-utilities', name: 'Energy & Utilities' },
			{ value: 'environmental-services', name: 'Environmental Services' },
			{ value: 'events', name: 'Events' },
			{ value: 'fashion', name: 'Fashion' },
			{ value: 'financial-services', name: 'Financial Services' },
			{ value: 'food', name: 'Food' },
			{ value: 'government-public-sector', name: 'Government & Public Sector' },
			{ value: 'healthcare-pharmaceuticals', name: 'Healthcare & Pharmaceuticals' },
			{ value: 'hospitality-tourism', name: 'Hospitality & Tourism' },
			{ value: 'it', name: 'IT' },
			{ value: 'information-technology', name: 'Information Technology & Software Services' },
			{ value: 'logistics', name: 'Logistics' },
			{ value: 'luxury', name: 'Luxury Travel' },
			{ value: 'manufacturing', name: 'Manufacturing' },
			{ value: 'maritime-shipping', name: 'Maritime & Shipping' },
			{ value: 'media-entertainment', name: 'Media & Entertainment' },
			{ value: 'music', name: 'Music' },
			{ value: 'people', name: 'People' },
			{ value: 'professional', name: 'Professional' },
			{ value: 'professional-services', name: 'Professional Services' },
			{ value: 'property', name: 'Property' },
			{ value: 'real-estate', name: 'Real Estate & Property Management' },
			{ value: 'retail-wholesale', name: 'Retail & Wholesale Trade' },
			{ value: 'science', name: 'Science' },
			{ value: 'sports', name: 'Sports' },
			{ value: 'telecommunications', name: 'Telecommunications' },
			{ value: 'transportation-logistics', name: 'Transportation & Logistics' },
			{ value: 'other', name: 'Other' },
		]

	},
}