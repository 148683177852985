import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import * as user from '@/store/modules/user.js';
import * as countries from '@/store/modules/countries.js';
import * as permissions from '@/store/modules/permissions.js';
import * as locales from '@/store/modules/locales.js';
import * as site from '@/store/modules/site.js';

export default createStore({
	state:{},
	mutations:{},
	actions:{},
	modules:{
		user,
		countries,
		permissions,
		locales,
		site
	},
	plugins:[
        createPersistedState()
    ],
});