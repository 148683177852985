export const namespaced = true

export const getters = {
	store_locales: () => {
		return [
			{"name":"Afrikaans","value":"af"},
			{"name":"Amharic","value":"am"},
			{"name":"Aragonese","value":"arg"},
			{"name":"Arabic","value":"ar"},
			{"name":"Moroccan Arabic","value":"ary"},
			{"name":"Assamese","value":"as"},
			{"name":"Azerbaijani","value":"az"},
			{"name":"South Azerbaijani","value":"azb"},
			{"name":"Belarusian","value":"bel"},
			{"name":"Bulgarian","value":"bg_BG"},
			{"name":"Bengali (Bangladesh)","value":"bn_BD"},
			{"name":"Tibetan","value":"bo"},
			{"name":"Bosnian","value":"bs_BA"},
			{"name":"Catalan","value":"ca"},
			{"name":"Cebuano","value":"ceb"},
			{"name":"Czech","value":"cs_CZ"},
			{"name":"Welsh","value":"cy"},
			{"name":"Danish","value":"da_DK"},
			{"name":"German","value":"de_DE"},
			{"name":"German (Switzerland, Informal)","value":"de_CH_informal"},
			{"name":"German (Switzerland)","value":"de_CH"},
			{"name":"German (Formal)","value":"de_DE_formal"},
			{"name":"German (Austria)","value":"de_AT"},
			{"name":"Lower Sorbian","value":"dsb"},
			{"name":"Dzongkha","value":"dzo"},
			{"name":"Greek","value":"el"},
			{"name":"English (Canada)","value":"en_CA"},
			{"name":"English (South Africa)","value":"en_ZA"},
			{"name":"English (UK)","value":"en_GB"},
			{"name":"English (Australia)","value":"en_AU"},
			{"name":"English (New Zealand)","value":"en_NZ"},
			{"name":"Esperanto","value":"eo"},
			{"name":"Spanish (Venezuela)","value":"es_VE"},
			{"name":"Spanish (Argentina)","value":"es_AR"},
			{"name":"Spanish (Spain)","value":"es_ES"},
			{"name":"Spanish (Mexico)","value":"es_MX"},
			{"name":"Spanish (Dominican Republic)","value":"es_DO"},
			{"name":"Spanish (Peru)","value":"es_PE"},
			{"name":"Spanish (Chile)","value":"es_CL"},
			{"name":"Spanish (Uruguay)","value":"es_UY"},
			{"name":"Spanish (Puerto Rico)","value":"es_PR"},
			{"name":"Spanish (Costa Rica)","value":"es_CR"},
			{"name":"Spanish (Guatemala)","value":"es_GT"},
			{"name":"Spanish (Ecuador)","value":"es_EC"},
			{"name":"Spanish (Colombia)","value":"es_CO"},
			{"name":"Estonian","value":"et"},
			{"name":"Basque","value":"eu"},
			{"name":"Persian","value":"fa_IR"},
			{"name":"Persian (Afghanistan)","value":"fa_AF"},
			{"name":"Finnish","value":"fi"},
			{"name":"French (Canada)","value":"fr_CA"},
			{"name":"French (Belgium)","value":"fr_BE"},
			{"name":"French (France)","value":"fr_FR"},
			{"name":"Friulian","value":"fur"},
			{"name":"Frisian","value":"fy"},
			{"name":"Scottish Gaelic","value":"gd"},
			{"name":"Galician","value":"gl_ES"},
			{"name":"Gujarati","value":"gu"},
			{"name":"Hazaragi","value":"haz"},
			{"name":"Hebrew","value":"he_IL"},
			{"name":"Hindi","value":"hi_IN"},
			{"name":"Croatian","value":"hr"},
			{"name":"Upper Sorbian","value":"hsb"},
			{"name":"Hungarian","value":"hu_HU"},
			{"name":"Armenian","value":"hy"},
			{"name":"Indonesian","value":"id_ID"},
			{"name":"Icelandic","value":"is_IS"},
			{"name":"Italian","value":"it_IT"},
			{"name":"Japanese","value":"ja"},
			{"name":"Javanese","value":"jv_ID"},
			{"name":"Georgian","value":"ka_GE"},
			{"name":"Kabyle","value":"kab"},
			{"name":"Kazakh","value":"kk"},
			{"name":"Khmer","value":"km"},
			{"name":"Kannada","value":"kn"},
			{"name":"Korean","value":"ko_KR"},
			{"name":"Kurdish (Sorani)","value":"ckb"},
			{"name":"Lao","value":"lo"},
			{"name":"Lithuanian","value":"lt_LT"},
			{"name":"Latvian","value":"lv"},
			{"name":"Macedonian","value":"mk_MK"},
			{"name":"Malayalam","value":"ml_IN"},
			{"name":"Mongolian","value":"mn"},
			{"name":"Marathi","value":"mr"},
			{"name":"Malay","value":"ms_MY"},
			{"name":"Myanmar (Burmese)","value":"my_MM"},
			{"name":"Norwegian (Bokm\u00e5l)","value":"nb_NO"},
			{"name":"Nepali","value":"ne_NP"},
			{"name":"Dutch (Formal)","value":"nl_NL_formal"},
			{"name":"Dutch (Belgium)","value":"nl_BE"},
			{"name":"Dutch","value":"nl_NL"},
			{"name":"Norwegian (Nynorsk)","value":"nn_NO"},
			{"name":"Occitan","value":"oci"},
			{"name":"Panjabi (India)","value":"pa_IN"},
			{"name":"Polish","value":"pl_PL"},
			{"name":"Pashto","value":"ps"},
			{"name":"Portuguese (Brazil)","value":"pt_BR"},
			{"name":"Portuguese (Portugal)","value":"pt_PT"},
			{"name":"Portuguese (Angola)","value":"pt_AO"},
			{"name":"Portuguese (Portugal, AO90)","value":"pt_PT_ao90"},
			{"name":"Rohingya","value":"rhg"},
			{"name":"Romanian","value":"ro_RO"},
			{"name":"Russian","value":"ru_RU"},
			{"name":"Sakha","value":"sah"},
			{"name":"Sindhi","value":"snd"},
			{"name":"Sinhala","value":"si_LK"},
			{"name":"Slovak","value":"sk_SK"},
			{"name":"Saraiki","value":"skr"},
			{"name":"Slovenian","value":"sl_SI"},
			{"name":"Albanian","value":"sq"},
			{"name":"Serbian","value":"sr_RS"},
			{"name":"Swedish","value":"sv_SE"},
			{"name":"Swahili","value":"sw"},
			{"name":"Silesian","value":"szl"},
			{"name":"Tamil","value":"ta_IN"},
			{"name":"Tamil (Sri Lanka)","value":"ta_LK"},
			{"name":"Telugu","value":"te"},
			{"name":"Thai","value":"th"},
			{"name":"Tagalog","value":"tl"},
			{"name":"Turkish","value":"tr_TR"},
			{"name":"Tatar","value":"tt_RU"},
			{"name":"Tahitian","value":"tah"},
			{"name":"Uighur","value":"ug_CN"},
			{"name":"Ukrainian","value":"uk"},
			{"name":"Urdu","value":"ur"},
			{"name":"Uzbek","value":"uz_UZ"},
			{"name":"Vietnamese","value":"vi"},
			{"name":"Chinese (Hong Kong)","value":"zh_HK"},
			{"name":"Chinese (Taiwan)","value":"zh_TW"},
			{"name":"Chinese (China)","value":"zh_CN"}
		]
	},
}