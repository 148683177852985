export const namespaced = true

export const state = {
	user: null,
}

export const mutations = {
	SET_USER( state, user ) {
		state.user = user
	}
}

export const actions = {

}

export const getters = {
	auth_user: ( state ) => {
		return state.user
	},
}