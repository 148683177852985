import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
window.$ = window.jQuery = $
import '@/assets/scss/style.scss'
import 'bootstrap'
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'

const app = createApp(App);

app.use(router);

app.use(store);

app.use(Vue3Tour)

app.mount('#app')

$(document).on('click', function() {
	$( '.bs-tooltip-auto' ).remove();
	$('[data-bs-toggle="tooltip"]').tooltip('hide');
});

$(document).on('mouseenter', '[data-bs-toggle="tooltip"]', function() {
	$(this).tooltip('show');
});

$(document).on('mouseleave', '[data-bs-toggle="tooltip"]', function() {
	$(this).tooltip('hide');
});